<template>
    <h1 v-if="showEditButton">
        <b-tooltip target="_self" :title="`Edit ${user.firstName} ${user.lastName}`">
            <router-link :to="{name: 'EditUser', params: {idUser: idUser}}">{{ user.firstName }} {{ user.lastName }}
                <button class="bi bi-pencil-square" alt="Edit User"></button>
            </router-link>
        </b-tooltip>
    </h1>
    <h1 v-else>
        {{ user.firstName }} {{ user.lastName }}
    </h1>
    <div class="row">
        <div class="col">
            <label>First Name</label>
            <input class="form-control" v-model="user.firstName" disabled/>
        </div>
        <div class="col">
            <label>Country</label>
            <input class="form-control" v-model="user.countryName" disabled/>
        </div>
        <div class="col">
            <label>MyESU Account Status:</label>
            <p>
                <span style="color: black; font-weight: normal;">
                    {{ user.enabled ? 'Active' : 'Inactive' }}
                </span>
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <label>Last Name</label>
            <input class="form-control" v-model="user.lastName" disabled/>
        </div>
        <div class="col">
            <label>State</label>
            <input class="form-control" v-if="user.countryCode === 'US'" v-model="user.stateName" disabled/>
            <input class="form-control" v-else placeholder="No US State" disabled/>
        </div>
        <div class="col">
            <label>Registration Date:

            </label>
            <p>
                <span style="color: black; font-weight: normal">{{ user.registrationDate }}</span>
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <label>Email</label>
            <input class="form-control" v-model="user.email" disabled/>
        </div>
        <div class="col">
            <div v-if="showDistrict">
                <label>District</label>
                <input class="form-control" v-model="user.districtName" disabled/>
            </div>
            <div v-else-if="!user.inNebraska">
                <div>
                    <label>Organization</label>
                    <input class="form-control" v-model="user.outStateOrgName" disabled/>
                </div>
            </div>
        </div>
        <div class="col">
            <label>Last Login:</label>
            <p><span
                    style="color: black; font-weight: normal">{{
                (user.lastLoginDate !== null && user.lastLoginDate !== undefined) ? user.lastLoginDate : "Has Not Logged In Yet"
                }}</span></p>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div v-if="user.inNebraska">
                <label>ESU</label>
                <input class="form-control" v-model="user.esuName" disabled/>

            </div>
        </div>
        <div class="col">
            <div v-if="showSchool">
                <label>School</label>
                <input class="form-control" v-model="user.schoolName" disabled/>
            </div>
        </div>
        <div class="col">
            <div v-if="showSupervisingUsers">
                <!--                <hr/>-->

                <label>Supervisors</label>
                <div class="accordion">
                    <div v-if="supervisingUsers.esuAdmins.length > 0">
                        <h4 class="accordion-header">
                            <button
                                    class="accordion-button collapsed"
                                    id="esu-admin-header"
                                    aria-controls="esu-admins"
                                    data-toggle="collapse"
                                    data-target="#esu-admins" style="height: 38px">ESU Admins
                            </button>
                        </h4>
                        <div id="esu-admins" class="accordion-collapse collapse" aria-labelledby="esu-admin-header">
                            <div v-for="esuAdmin in supervisingUsers.esuAdmins" :key="esuAdmin" class="card">
                                <UserInfo :user="esuAdmin" :show-edit-route="false"
                                          :show-view-route="authUser.position.roleHierarchy < esuAdmin.roleHierarchyId"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion" v-if="supervisingUsers.districtAdmins.length > 0">
                    <div v-if="supervisingUsers.districtAdmins.length > 0">
                        <h4 class="accordion-header">
                            <button class="accordion-button collapsed"
                                    id="district-admin-header"
                                    aria-controls="district-admins"
                                    data-toggle="collapse"
                                    data-target="#district-admins" style="height: 38px">District Admins
                            </button>
                        </h4>
                        <div id="district-admins" class="accordion-collapse collapse"
                             aria-labelledby="district-admin-header">
                            <div v-for="districtAdmin in supervisingUsers.districtAdmins" :key="districtAdmin"
                                 class="card">
                                <UserInfo :user="districtAdmin"
                                          :show-view-route="authUser.position.roleHierarchy < districtAdmin.roleHierarchyId"
                                          :show-edit-route="false"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion">
                    <div v-if="supervisingUsers.instructors.length > 0">
                        <h4 class="accordion-header">
                            <button class="accordion-button collapsed"
                                    id="instructor-header"
                                    aria-controls="instructors"
                                    data-toggle="collapse"
                                    data-target="#instructors" style="height: 38px">Supervisor
                            </button>
                        </h4>
                        <div id="instructors" class="accordion-collapse collapse" aria-labelledby="instructors">

                            <div v-for="instructor in supervisingUsers.instructors" :key="instructor" class="card">
                                <UserInfo :user="instructor"
                                          :show-view-route="authUser.position.roleHierarchy < instructor.roleHierarchyId"
                                          :show-edit-route="false"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="noSupervisingUsersMessage !== ''">
                    {{ noSupervisingUsersMessage }}
                </div>
            </div>

        </div>
    </div>

    <hr/>
    <div class="row">
        <div v-if="showEditButton" class="col-sm">
            <router-link :to="{name: 'EditUser', params: {idUser: idUser}}">
                <button class="btn-primary button">Edit {{ user.firstName }} {{ user.lastName }}</button>
            </router-link>
        </div>
        <div class="col-sm" v-if="user.canvasId !== null">
            <router-link :to="{name: 'CertificationReport', params: {idUser: idUser}}"
                         @mouseenter="hoveringReportButton = true" @mouseleave="hoveringReportButton = false">
                <button :class="certReportIcon" :style="certReportStyle"> Certification Report</button>
            </router-link>
        </div>
    </div>

</template>

<script>
import {computed, onBeforeMount, ref} from "vue";
import axios from "axios";
import {useStore} from "vuex";
import {API_URL} from "../../../Constants";
import {isDistrictAdmin, isNde, isParaEducatorCollegeStudentOrEarlyChildhood, isSuperUser} from "@/RoleCheck";
import UserInfo from "@/components/UserOrganizationHierarchy/UserInfo";
import {isEmpty} from "lodash/lang";

export default {
	name: "ViewUser",
	components: {UserInfo},
	props: {
		idUser: String,
	},
	setup(props, context) {
		const store = useStore();
		const authToken = computed(() => store.getters['auth/authenticated']);
		const authUser = computed(() => store.getters['auth/user']);
		const user = ref({});
		const supervisingUsers = ref([]);
		const viewingUser = ref({});
		const hoveringReportButton = ref(false);

		onBeforeMount(() => {
			getUser();
			getViewingUser();
		})

		const showDistrict = computed(() => {
			return user.value.districtName !== "" && user.value.districtName !== null && user.value.districtName !== undefined;
		})

		const showSchool = computed(() => {
			return user.value.schoolName !== "" && user.value.schoolName !== null && user.value.schoolName !== undefined;
		})

		const showEditButton = computed(() => {
			if (isNde(viewingUser.value.roleHierarchyId)) return false;

			if (viewingUser.value.roleHierarchyId === 1) {
				return true
			}

			if (viewingUser.value.idUser === user.value.idUser) {
				return true;
			}

			let da_edit_admin = (isDistrictAdmin(viewingUser.value.roleHierarchyId) && isDistrictAdmin(user.value.roleHierarchyId));

			let supervising;

			if (user.value.inNebraska) {
				switch (viewingUser.value.roleHierarchyId) {
					case 2:
						supervising = user.value.idEsu === viewingUser.value.idEsu;
						break;
					case 3:
						supervising = user.value.idDistrict === viewingUser.value.idDistrict;
						break;
					case 4:
						supervising = user.value.idSchool === viewingUser.value.idSchool;
						break;
					default:
						supervising = false;
						break;
				}
			} else {
				supervising = user.value.idOutstateOrganization === viewingUser.value.idOutstateOrganization;
			}

			return (viewingUser.value.roleHierarchyId < user.value.roleHierarchyId && supervising) || da_edit_admin;
		})

		const showReport = computed(() => {
			return user.value.canvasId !== null && isParaEducatorCollegeStudentOrEarlyChildhood(user.value.roleHierarchyId) && showEditButton.value;
		})

		const showHistoricalMessage = computed(() => {
			return showReport.value && !user.value.accountEnabled;
		})

		async function getUser() {
			let get_uri = API_URL + "/user/byId?_idUser=" + parseInt(atob(props.idUser));
			await axios.get(get_uri, {
				headers: {
					"Authorization": "Bearer " + authToken.value
				}
			}).then((result) => {
				user.value = result.data;
			}).finally(() => {
				if (user.value.roleHierarchyId !== 6 && user.value.roleHierarchyId >= 3) {
					getSupervisingUsers();
				}
			})
		}

		async function getViewingUser() {
			let get_uri = API_URL + "/user/byId?_idUser=" + parseInt(authUser.value.idUser);
			await axios.get(get_uri, {
				headers: {
					"Authorization": "Bearer " + authToken.value
				}
			}).then((result) => {
				viewingUser.value = result.data;
			})
		}

		async function getSupervisingUsers() {
			let get_uri = API_URL + "/user/supervisingUsers";
			await axios.get(get_uri, {
				params: {
					_idUser: parseInt(atob(props.idUser))
				}
			}).then((result) => {
				supervisingUsers.value = result.data;
			})
		}

		const noSupervisingUsersMessage = computed(() => {
			return (supervisingUsers.value.esuAdmins.length === 0 &&
				supervisingUsers.value.districtAdmins.length === 0
				&& supervisingUsers.value.instructors.length === 0) ? "No supervisors for this user are currently active in MyESU" : "";
		})

		const showSupervisingUsers = computed(() => {
			return !isEmpty(supervisingUsers.value);
		})

		const certReportStyle = computed(() => {
			return hoveringReportButton.value ? "background-color: #a5bcff; color: #000" : " background-color: royalblue; color: #fff";
		})

		const certReportIcon = computed(() => {
			return hoveringReportButton.value ? "bi-bar-chart-fill button btn-secondary" : "bi-bar-chart-fill button btn-secondary";
		})

		return {
			user,
			authUser,
			showDistrict,
			showSchool,
			showEditButton,
			showReport,
			showHistoricalMessage,
			supervisingUsers,
			noSupervisingUsersMessage,
			showSupervisingUsers,
			certReportStyle,
			certReportIcon,
			hoveringReportButton,
		}
	}
}
</script>

<style scoped>
.accordion-header {
    border: 1px solid black;
}
</style>